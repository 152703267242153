import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ProjectCard = makeShortcode("ProjectCard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Projects`}</h2>
    <p>{`The Following Are Some Of My Projects.`}</p>
    <ProjectCard title="the mCloud" link="https://the-mcloud.ml" bg="linear-gradient(to right, #D4145A 0%, #FBB03B 100%)" mdxType="ProjectCard">
This project is my very own web hosting company, its a traditional host, so we still don't have support for React and Gatsby.
    </ProjectCard>
    <ProjectCard title="MolaiBOT" link="https://dsc.gg/molaibot" bg="linear-gradient(to right, #b92b27, #1565c0);" mdxType="ProjectCard">
This is my own discord bot, which is publicly available, it will make moderating your discord server a lot easier for you, I plan to add music functionality to it soon.
    </ProjectCard>
    <ProjectCard title="MTGSquad's CDN" link="https://cdn.mtgsquad-dev.com/" bg="linear-gradient(to right, #ff416c, #ff4b2b);" mdxType="ProjectCard">
MTGSquad's CDN is a content delivery network which you can use to host images, css files, and javascript files. This is super-fast and useful, I myself use the CDN all the time, look at the guide on the repository here: <a href="https://github.com/mtgsquad/cdn-content#-mtgsquads-cdn">Add Your Own Content Guide</a>
    </ProjectCard>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      