import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`About`}</h1>
    <p>{`I'm a 13 year old, who was first interested in how iOS apps are made, Then I wanted to know what was behind websites, and desktop apps. So I started coding myself, then ended up as javascript developer. I still try to work as hard as possible. My dream is to get a job at google as a javascript developer, or software engineer, I'll aim for a javascript related job, since most software engineer interviews have algorithm questions. But we'll see that when I'm 18 and ready to start working.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      